<template>
  <l-map ref="myMap" :max-zoom="16" @ready="initMap()" style="z-index: 1">
    <slot />
  </l-map>
</template>

<script>
import { Icon } from 'leaflet'
import { LMap } from 'vue2-leaflet'
import { GestureHandling } from 'leaflet-gesture-handling'
import { vectorBasemapLayer } from 'esri-leaflet-vector'

import 'mapbox-gl/dist/mapbox-gl.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

// Fix icons:
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    LMap,
  },
  props: {
    center: { type: Array, default: () => [51.8656401, 5.8616097] },
    zoom: { type: Number, default: 5 },
    disableGestures: { type: Boolean, default: false },
  },
  data: () => ({
    map: null,
  }),
  computed: {
    xyz() {
      return `${this.center}-${this.zoom}`
    },
  },
  watch: {
    xyz: {
      immediate: true,
      handler() {
        this.map && this.map.setView(this.center, this.zoom)
      },
    },
  },
  methods: {
    initMap() {
      this.map = this.$refs.myMap.mapObject
      this.map.setView(this.center, this.zoom)

      vectorBasemapLayer('ArcGIS:LightGray', { apiKey: process.env.VUE_APP_ESRI_API_KEY }).addTo(this.map)

      // Disable scroll and swipe is requested:
      if (this.disableGestures) {
        this.map.addHandler('gestureHandling', GestureHandling)
        this.map.gestureHandling.enable()
      }
    },
  },
}
</script>

<style lang="sass">
.leaflet-control-attribution a
  color: #323232
</style>
