export default {
  watch: {
    tlToolbarDark: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setDark', newVal)
      },
    },
    tlToolbarBtnLeft: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setBtnLeft', newVal)
      },
    },
    tlToolbarTitle: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setTitle', newVal)
      },
    },
    tlToolbarButtons: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setButtons', newVal)
      },
    },
    tlToolbarRightComp: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setRightComp', newVal)
      },
    },
    tlToolbarTabs: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setTabs', newVal)
      },
    },
    tlToolbarBanners: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setBanners', newVal)
      },
    },
    tlToolbarSearchable: {
      immediate: true,
      handler(newVal) {
        this.$store.commit('toolbar/setSearchable', newVal)
      },
    },
  },
}
