<template>
  <l-marker v-bind="$attrs" :lat-lng="latLng" :icon="myIcon" @click="onClick">
    <slot />
  </l-marker>
</template>

<script>
import { LMarker } from 'vue2-leaflet'
import L from 'leaflet'

export default {
  props: {
    latLng: { type: Array, default: () => [51.8656401, 5.8616097] },
    onClick: { type: Function, default: () => {} },
  },
  components: {
    LMarker,
  },
  data: () => ({
    myIcon: L.icon({
      iconUrl: require('@/components/shared/leaflet/map-marker.png'),
      iconSize: [75, 83],
      iconAnchor: [37, 83],
      popupAnchor: [0, -83],
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
      shadowAnchor: [13, 40],
    }),
  }),
}
</script>

<style lang="sass">
.tl-map-tooltip
  background: rgba(97, 97, 97, 0.9)
  color: #FFFFFF
  border-radius: 4px
  font-size: 14px
  line-height: 22px
  padding: 5px 16px
  border: none
</style>
